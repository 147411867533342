import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { layout, position, typography } from 'styled-system';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { Flex } from '../elements';

const H1 = styled.h1`
  color: white;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 140px;
  font-family: 'Arial-Black', 'Arial', sans-serif;
  margin-bottom: 120px;
  ${typography}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 0px 15px 10px 15px;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 150px;
  font-family: 'Arial-Black', 'Arial', sans-serif;
  color: #979797;
  position: relative;
  &:hover, &:active {
    color: #F07D00;
  }
  ${position}
  ${layout}
  ${typography}
`;

const StyledLinkWithSeparator = styled(StyledLink)`
  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -10px;
    width: calc(100% - 30px);
    height: 3px;
    background-color: #979797;
  }
`;

const IndexPage = ({ location }) => {
  return (
    <Layout location={location} header={false}>
      <SEO />
      <Flex flexDirection="column" height="100%" alignItems="center" justifyContent="center">
        <H1 fontSize={['52px', '52px', '80px', '90px', '132px']}>ARIK LEVY</H1>
        <StyledLinkWithSeparator
          fontSize={['52px', '52px', '80px', '90px', '132px']}
          to="/art"
          display="block"
        >
          Art
        </StyledLinkWithSeparator>
        <StyledLink
          fontSize={['52px', '52px', '80px', '90px', '132px']}
          to="/design"
          display="block"
        >
          Design
        </StyledLink>
      </Flex>
    </Layout>
  );
};

export default IndexPage;
